<template>
  <div
    v-if="isLoading"
    style="
      height: 500px;
      display: flex;
      justify-content: center;
      align-items: center;
    "
  >
    <div class="text-center px-5 pb-7">
      <img
        alt="Logo"
        class="max-h-35px"
        src="/media/loading_icon.gif"
        style="width: 40px"
      />
    </div>
  </div>

  <div class="row g-6 g-xl-9">
  <div
    class="col-sm-6 col-xl-4"
    v-if="moduleList.length > 0"
    v-for="module in productList"
    :key="module.product.id"
  >
    <div class="card h-100">
      <div class="card-body d-flex flex-column">
        <!--begin::Indicator-->
        <div class="row">

            <div class="col-md-4">
              <div
                class="symbol symbol-100px symbol-circle w-45px ms-4 mt-2"
                style="padding-right: 0px; align-items: center;"
              >
                <img src="/media/images/2432846.png" class="p-1" />
              </div>
            </div>
            <div class="col-md-8">
              <div class="fw-bolder mt-9 ms-2" style="padding-left: 0px">
                <a href="#" style="color: black; font-size: 18px">{{
                  module.product.name
                }}</a>
                <!--
              <span class="badge bg-light text-gray-700 px-3 py-2 me-2">
                {{ $t("modalList.availableForPurchase") }}
              </span>
              -->
                <!--begin::Number-->
                <div class="text-success fw-bolder me-2" style="font-size: 12px">
                  {{ $t("modalList.availableForPurchase") }}
                </div>
                <!--end::Number-->
              </div>
            </div>

        </div>
        <!--end::Indicator-->

        <!--begin::Stats-->
        <div class="
            d-flex
            align-items-center
            flex-wrap
            mb-5
            mt-auto
            fs-6
            justify-content-center
            flex-grow-1
          ">
          <!--begin::Label-->
          <div class="fw-bold text-gray-600 text-center mt-4 fs-7">
            {{ module.product.description }}
          </div>
          <!--end::Label-->
        </div>
        <!--end::Stats-->

        <router-link
          class="btn btn-sm btn-primary"
          style="color: white"
           :to="'/moduleDetail/' + module.product.id"
        >
          <!--begin::Button-->
          {{ $t("common.button.install") }}
        </router-link>
        <!--end::Button-->
      </div>
      <!--end::Card body-->
    </div>
  </div>
  

  <div v-else class="col-sm-6 col-xl-4 card-px">
    <!--begin::Description-->
    <p class="text-gray-400 fs-5 fw-bold">
      <span>{{ $t("menuPages.animalList.zeroCount") }}</span>
    </p>
    <!--end::Description-->
  </div>
</div>


  <!--begin::Pagination-->
  <div
    class="d-flex flex-stack flex-wrap pt-10"
    v-if="moduleList.length != 0 && moduleList.length >= 5"
  >
    <!--begin::Controls-->
    <div class="d-flex flex-wrap my-1">
      <!--begin::Select wrapper-->
      <div class="m-0">
        <!--begin::Select-->
        <select
          name="status"
          data-control="select2"
          data-hide-search="true"
          class="form-select form-select-white form-select-sm fw-bolder w-125px"
          v-model="pageSize"
        >
          <option value="5" selected>5</option>
          <option value="10">10</option>
          <option value="25">25</option>
          <option value="50">50</option>
        </select>
        <span>{{ $t("common.table.pagination.desc2") }}</span>

        <!--end::Select-->
      </div>
      <!--end::Select wrapper-->
    </div>
    <!--end::Controls-->

    <div class="fs-6 fw-bold text-gray-700">
      {{ totalItems }} {{ $t("common.table.pagination.desc") }}
      {{ (currentPage - 1) * pageSize + 1 }} -
      {{ Math.min(currentPage * pageSize, totalItems) }}
      {{ $t("common.table.pagination.desc1") }}
    </div>

    <!--begin::Pages-->
    <ul class="pagination">
      <li class="page-item previous" :class="{ disabled: currentPage === 1 }">
        <a href="#" class="page-link"><i class="previous"></i></a>
      </li>

      <li
        v-for="page in pageCount"
        :key="page"
        class="page-item"
        :class="{ active: currentPage === page }"
      >
        <a href="#" class="page-link">{{ page }}</a>
      </li>

      <li
        class="page-item next"
        :class="{ disabled: currentPage === pageCount }"
      >
        <a href="#" class="page-link"><i class="next"></i></a>
      </li>
    </ul>
    <!--end::Pages-->
  </div>
  <!--end::Pagination-->

  <div v-else-if="moduleList.length == 0" class="card-px text-center">
    <div class="text-center px-5 pb-7">
      <img
        src="/media/animal/module.jpg"
        class="symbol symbol-100px symbol-lg-160px mw-100 mh-300px"
      />
    </div>

    <!--begin::Description-->
    <p class="text-gray-400 fs-5 fw-bold">
      <span>{{ $t("modalList.notFound") }}</span>
    </p>
    <!--end::Description-->
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, watch } from "vue";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { ProductListModel } from "@/domain/product/productList/model/ProductListModel";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import { PAGE_TITLE_PATH } from "@/presentation/constants/PageTitle";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";

export default defineComponent({
  name: "module-list",
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const isLoading = ref<boolean>(false);
    const swalNotification = new SwalNotification();
    const currentPage = ref(1);
    const pageSize = ref(5);
    const totalItems = ref(0);
    const pageCount = ref(1);
    const productController = store.state.ControllersModule.productController;
    const productList = ref<ProductListModel[]>([]);

    const moduleList = ref<any[]>([
      {
        id: 1,
        title: "AI Otel Asistanı",
        desc: "AI Otel Asistanı modülü, oteliniz ile ilgili gelen yorumları yapay zeka modeline ileterek onların değerlendirilmesini amaçlar. ",
        to: "/hotelManagementDetail",
      },
      {
        id: 2,
        title: "Anketör",
        desc: "Bu modülle, personellere ürünler ve çeşitli konular hakkında yapay zeka destekli görüntülü ve sesli anketler yapabilirsiniz. ",
        to: "/pollsterDetail",
      },
      {
        id: 3,
        title: "Mülakat",
        desc: "Bu modülle, işe alım süreçlerini optimize etme ve modernize etme imkanına sahip olabilirsiniz. ",
        to: "/interviewDetail",
      },
      {
        id: 4,
        title: "Örnek Modül",
        desc: "Modulün kendi açıklamasını buraya yazıyoruz.",
        to: "/hotelManagementDetail",
      },
    ]);

    const getModuleList = async () => {
      isLoading.value = true;

      const productListModel: ProductListModel = {
        product: {
          productPackages: []
        },
        locale: "tr",
      };

      productController
        .productList(productListModel)
        .then((response) => {
          if (response.isSuccess) {
            response.getValue().forEach((product) => {
              productList.value.push(product);
            });
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
          isLoading.value = false;
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    onMounted(async () => {
      setCurrentPageTitle(t("modalList.title"));
      getModuleList();
    });

    return {
      PAGE_TITLE_PATH,
      currentPage,
      pageCount,
      pageSize,
      isLoading,
      totalItems,
      moduleList,
      productList,
    };
  },
});
</script>

<style lang="scss">
table.dataTable {
  clear: both;
  margin-top: 6px !important;
  margin-bottom: 6px !important;
  max-width: none !important;
  border-collapse: separate !important;
  border-spacing: 0;
}

table.dataTable > thead {
  th.sorting {
    position: relative;
  }
  .sorting:after {
    position: absolute;
  }
}

.el-pagination.is-background .btn-next,
.el-pagination.is-background .btn-prev,
.el-pagination.is-background .el-pager li {
  background: none;
  border-radius: 0.475rem;
  font-weight: 500;
  font-size: 1.075rem;
  font-family: Poppins, Helvetica, sans-serif;
}

.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #009ef7;
}
</style>
